import React from "react";

const PageNotFound = () => (
  <main>
    <h1>PageNotFound</h1>
    <p>Welcome to my fake PageNotFound.</p>
  </main>
);

export default PageNotFound;
